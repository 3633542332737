import request from "@/api/request";

/**
 * 查询用户详情
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getUserById(params) {
    return await request.post("/user/userById",params)
}
