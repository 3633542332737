<template>
  <div style="margin-top: 60px">
    <van-tabbar v-model="active" active-color="#ee0a24" inactive-color="#000" route>
      <van-tabbar-item icon="records" to="/home">记账</van-tabbar-item>
      <van-tabbar-item icon="cash-back-record" to="/taxPayment">申报</van-tabbar-item>
      <van-tabbar-item icon="user-o" to="/myData">我的</van-tabbar-item>
    </van-tabbar>
  </div>


</template>

<script>
export default {
  name: "BottomBar",
  data() {
    return {
      active:0,
    }
  },
  methods:{
    onChange(index) {
      switch (index) {
        case 0:
          this.$router.replace("/home");
          break;
        case 1:
          this.$router.replace("/taxPayment");
          break;
        case 2:
          this.$router.replace("/myData");
          break;
        }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
