<template>

  <div>
    <transition name="van-slide-left">
      <div v-show="visible">
        <div class="head">
          <!-- 居中 -->
          <van-row type="flex" justify="center" style="text-align: center;padding-top: 60px">
            <van-col span="12"><h1 style="color: #FFFFFF;line-height: 10px">纳税</h1></van-col>
            <van-col span="12">
              <van-button round type="info" style="padding: 20px" @click="goDeclare">去申报</van-button>
            </van-col>
          </van-row>
        </div>
        <van-notice-bar scrollable text="入、纳为已纳税的状态" style="margin-top: 20px" />
        <van-pull-refresh v-model="isLoading" @refresh="getKeepDeclareList">
          <van-cell-group v-if="keepDeclareVoList.length > 0">
            <van-cell >
              <template #title>
                入：{{ ru }}
              </template>
              <template #default>
                纳：{{ na }}
              </template>
            </van-cell>
            <van-cell is-link center v-for="item in keepDeclareVoList" :key="item.id" :to="{path:'/declarationDetails',query:{keepDeclareId:item.id}}">
              <template #title>
                ￥{{ item.incomeMoney }}
                <van-tag plain type="primary" v-if="item.status === 2"> 审核中</van-tag>
                <van-tag plain type="danger" v-if="item.status === 0"> 已驳回</van-tag>
                <van-tag plain type="success" v-if="item.status === 1"> 已纳税</van-tag>
              </template>
              <template #default>
                <span style="color: #333333"> 预计纳税：￥{{ item.taxMoney }}</span>
              </template>
              <template #label>
                <p>{{ item.createdAt }}</p>
                <p>共{{ item.keepAccountsCount }}笔</p>
              </template>
            </van-cell>
          </van-cell-group>
          <van-empty description="这里什么也没有..." v-else/>
        </van-pull-refresh>
      </div>
    </transition>
    <BottomBar/>

  </div>
</template>

<script>
import BottomBar from "@/components/BottomBar";
import {getUserById} from "@/api/user/user";
import {getKeepDeclareList} from "@/api/declare/declare";

export default {
  name: "taxPayment",
  data() {
    return {
      isLoading: false,
      visible: false,
      direction: 'down',
      dateSelectShow: false,
      buttonColor1: '#e33d33',
      buttonColor2: '#e33d33',
      keepDeclareVoList: [],
      na: '',
      ru: '',
    }
  },
  components: {
    BottomBar,
  },
  methods: {
    async goDeclare() {
      let res = await getUserById(null);
      if (res) {
        if (res.realStatus === 0) {
          this.$dialog.alert({
            title: '提示',
            message: '您的账号未实名认证，请前往 “创扶企规宝” 公众号进行实名认证',
            theme: 'round-button',
          }).then(() => {
            // on close
          });
        } else {
          await this.$router.replace("/declaration");
        }
      }
    },
    async getKeepDeclareList() {
      this.isLoading = true;
      let res = await getKeepDeclareList(null);
      this.isLoading = false;
      if (res) {
        this.ru = res.ru;
        this.na = res.na;
        this.keepDeclareVoList = res.keepDeclareVoList;
      }
    }
  },
  mounted() {
    this.visible = true;
    this.getKeepDeclareList();
    document.title = this.$route.meta.title
  }

}
</script>

<style scoped>
/** {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/

.head {
  width: 100vw;
  height: 150px;
  border-radius: 0 0 30px 30px;
  background-color: #e33d33;
  box-shadow: 0px 15px 10px -15px;
}

.data-class {
  margin-top: 30px;
  background-color: #cd372e;
  font-size: 18px;
  color: white;
  border-radius: 10px
}

.iconfont {
  color: #e33d33;
}

/*#cd372e*/
</style>

